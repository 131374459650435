import type { AppRouteModule } from '@/router/types'
const agencyAudit: AppRouteModule = {
    path: '/agencyAudit',
    name: 'agencyAudit',
    component: () => import('@/views/agent/agencyAudit/index.vue'),
    meta: {
      title:'机构审批',
      hideMenu: true,
    },
  }

export default agencyAudit
