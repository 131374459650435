import { h } from 'vue'
import { defineStore } from 'pinia'
import type { RouteRecordRaw } from 'vue-router'
import type { ErrorMessageMode } from '@/types/axios'

import { store } from '@/store'
import { router } from '@/router'
import type { RoleEnum } from '@/enums/roleEnum'
import { PageEnum } from '@/enums/pageEnum'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, ROLES_KEY, USER_INFO_KEY, LOGIN_INFO_KEY, TENANT_INFO_KEY, BASE_HOME_PAGE_KEY } from '@/enums/cacheEnum'
import { PAGE_NOT_FOUND_ROUTE } from '@/router/routes/basic'
import { usePermissionStore } from '@/store/modules/permission'
import { useI18n } from '@/hooks/web/useI18n'
import { useMessage } from '@/hooks/web/useMessage'
import { getAuthCache, setAuthCache } from '@/utils/auth'
import { doLogout, getUserInfo, loginApi, mobileCodeLoginApi, smsLogin } from '@/api/base/user'
import { getUserProfileApi, switchDpetLoginApi } from '@/api/base/profile'
import type { GetUserInfoModel, LoginParams, CodeLoginParams, SmsLoginParams } from '@/api/base/model/userModel'
import type { LoginMode } from '@/views/base/login/useLogin.ts'

import { isArray } from '@/utils/is'
import { AppRouteRecordRaw } from '@/router/types'
 
interface UserState {
  userInfo: Nullable<GetUserInfoModel>
  accessToken?: string
  refreshToken?: string
  roleList: RoleEnum[]
  sessionTimeout?: boolean
  lastUpdateTime: number
  loginInfo: any
  systemTenantInfo: any,
  /**默认首页地址 */
  base_home_page: string
}

export const useUserStore = defineStore('app-user', {
  state: (): UserState => ({
    // user info
    userInfo: null,
    // token
    accessToken: undefined,
    refreshToken: undefined,
    // roleList
    roleList: [],
    // Whether the login expired
    sessionTimeout: false,
    // Last fetch time
    lastUpdateTime: 0,
    loginInfo: null,
    systemTenantInfo: null,
    base_home_page: ''
  }),
  getters: {
    getUserInfo(state): GetUserInfoModel {
      return state.userInfo || getAuthCache<GetUserInfoModel>(USER_INFO_KEY) || {}
    },
    getAccessToken(state): string {
      return state.accessToken || getAuthCache<string>(ACCESS_TOKEN_KEY)
    },
    getRefreshToken(state): string {
      return state.refreshToken || getAuthCache<string>(REFRESH_TOKEN_KEY)
    },
    getRoleList(state): RoleEnum[] {
      return state.roleList.length > 0 ? state.roleList : getAuthCache<RoleEnum[]>(ROLES_KEY)
    },
    getSessionTimeout(state): boolean {
      return !!state.sessionTimeout
    },
    getLastUpdateTime(state): number {
      return state.lastUpdateTime
    },
    getLoginInfo(state): any {
      return state.loginInfo || getAuthCache<string>(LOGIN_INFO_KEY)
    },
    getSystemTenantInfo(state): number {
      return state.systemTenantInfo || getAuthCache<string>(TENANT_INFO_KEY)
    },
    getBaseHomePage(state):string{
      return state.base_home_page || getAuthCache<string>(BASE_HOME_PAGE_KEY)
    }
  },
  actions: {
    setBaseHomePage(path:string) {
      this.base_home_page = path
      setAuthCache(BASE_HOME_PAGE_KEY, path)
    },
    setAccessToken(info: string | undefined) {
      this.accessToken = info || '' // for null or undefined value
      setAuthCache(ACCESS_TOKEN_KEY, info)
    },
    setRefreshToken(info: string | undefined) {
      this.refreshToken = info || '' // for null or undefined value
      setAuthCache(REFRESH_TOKEN_KEY, info)
    },
    setRoleList(roleList: RoleEnum[]) {
      this.roleList = roleList
      setAuthCache(ROLES_KEY, roleList)
    },
    setUserInfo(info: GetUserInfoModel | null) {
      this.userInfo = info
      this.lastUpdateTime = new Date().getTime()
      setAuthCache(USER_INFO_KEY, info)
    },
    setSessionTimeout(flag: boolean) {
      this.sessionTimeout = flag
    },
    setLoginInfo(info: any | null) {
      this.loginInfo = info || {}
      setAuthCache(LOGIN_INFO_KEY, info)
    },
    setSystemTenantInfo(info: any | null) {
      this.systemTenantInfo = info || {}
      setAuthCache(TENANT_INFO_KEY, info)
    },
    resetState() {
      this.userInfo = null
      this.accessToken = ''
      this.roleList = []
      this.sessionTimeout = false
    },
    /**
     * @description: login
     */
    async login(
      params: LoginParams & {
        goHome?: boolean
        mode?: ErrorMessageMode
        loginMode: LoginMode
        [key: string]: any
      },
    ): Promise<GetUserInfoModel | null> {
      try {
        const { goHome = true, mode, loginMode, ...loginParams } = params
        // console.log(loginMode, 'loginMode', loginParams, 'loginParams');
        let data
        if (loginMode === 0) {
          data = await loginApi(loginParams, mode)
        } else {
          data = await mobileCodeLoginApi(loginParams as any, mode)
        }
        const { accessToken, refreshToken } = data
        //设置租户引导信息
        this.setLoginInfo(data)
        // save token
        this.setAccessToken(accessToken)
        this.setRefreshToken(refreshToken)
        return this.afterLoginAction(goHome)
      }
      catch (error) {
        return Promise.reject(error)
      }
    },
    async smsLogin(
      params: SmsLoginParams & {
        goHome?: boolean
        mode?: ErrorMessageMode
      },
    ): Promise<GetUserInfoModel | null> {
      try {
        const { goHome = true, mode, ...smsLoginParams } = params
        const data = await smsLogin(smsLoginParams, mode)
        const { accessToken, refreshToken } = data
        // save token
        this.setAccessToken(accessToken)
        this.setRefreshToken(refreshToken)
        return this.afterLoginAction(goHome)
      }
      catch (error) {
        return Promise.reject(error)
      }
    },
    /**切换机构登录 */
    async switchDeptLogin(params){
      try {
        this.logout()
        const { goHome = true, ...loginParams } = params
        let data
        data = await switchDpetLoginApi(loginParams)
        const { accessToken, refreshToken } = data
        //设置租户引导信息
        this.setLoginInfo(data)
        // save token
        this.setAccessToken(accessToken)
        this.setRefreshToken(refreshToken)
        return this.afterLoginAction(goHome)
      }
      catch (error) {
        return Promise.reject(error)
      }
    },
    async afterLoginAction(goHome?: boolean): Promise<GetUserInfoModel | null> {
      if (!this.getAccessToken)
        return null
      // get user info
      const userInfo = await this.getUserInfoAction()

      const sessionTimeout = this.sessionTimeout
      if (sessionTimeout) {
        this.setSessionTimeout(false)
      }
      else {
        const permissionStore = usePermissionStore()
        let firstPath = this.base_home_page
        if (!permissionStore.isDynamicAddedRoute) {
          const routes = await permissionStore.buildRoutesAction()
          routes.forEach((route) => {
            try {
              router.addRoute(route as unknown as RouteRecordRaw)
            }
            catch (e) { }
          })
          router.addRoute(PAGE_NOT_FOUND_ROUTE as unknown as RouteRecordRaw)
          const firstRoute = permissionStore.backMenuList[0]
          firstPath = firstRoute.children&&firstRoute.children[0]?.path || firstRoute.path
          /**登陆后，如果有首页菜单权限，则跳转首页，反之跳转机构页 */
          if(firstPath.indexOf(PageEnum.BASE_HOME)>=0){
            firstPath = PageEnum.BASE_HOME
          }else{
            firstPath = PageEnum.AGENCY_DEPT
          }
          this.setBaseHomePage(firstPath)
          const RootRoute: AppRouteRecordRaw = {
            path: '/',
            name: 'Root',
            redirect: firstPath,
            meta: {
              title: 'Root',
            },
          }
          router.addRoute(RootRoute as unknown as RouteRecordRaw)
          permissionStore.setDynamicAddedRoute(true)
        }
        
        console.log(permissionStore.backMenuList,router.getRoutes(),'permissionStore')
      // if (this.getLoginInfo?.doGuide === 1) {
        
        goHome && (await router.replace(firstPath))
        // }
      }
      return userInfo
    },
    async getUserInfoAction(): Promise<GetUserInfoModel | null> {
      if (!this.getAccessToken)
        return null
      const userInfo = await getUserInfo()
      const userProfile = await getUserProfileApi()
      const roleNames = userProfile?.userRoles || userProfile?.roles&&userProfile?.roles.map((x: GetUserInfoModel) => x.name)
      userInfo.roleNames = roleNames
      // console.log(userInfo,'userInfo');
      // (userInfo.menus[0] as any).icon = 'ant-design:appstore-outlined';
      // (userInfo.menus[0]as any).children[0].icon = 'ant-design:appstore-outlined';
      const { roles = [] } = userInfo
      if (isArray(roles)) {
        const roleList = roles.map(item => item) as RoleEnum[]
        this.setRoleList(roleList)
      }
      else {
        userInfo.roles = []
        this.setRoleList([])
      }
      this.setUserInfo(userInfo)
      return userInfo
    },
    /**
     * @description: logout
     */
    async logout(goLogin = false) {
      if (this.getAccessToken) {
        try {
          await doLogout()
        }
        catch {
          console.log('注销Token失败')
        }
      }
      this.setAccessToken(undefined)
      this.setSessionTimeout(false)
      this.setUserInfo(null)
      this.setLoginInfo(null)
      goLogin && router.push(PageEnum.BASE_LOGIN)
    },

    /**
     * @description: Confirm before logging out
     */
    confirmLoginOut() {
      const { createConfirm } = useMessage()
      const { t } = useI18n()
      createConfirm({
        iconType: 'warning',
        title: () => h('span', t('sys.app.logoutTip')),
        content: () => h('span', t('sys.app.logoutMessage')),
        onOk: async () => {
          await this.logout(true)
        },
      })
    },
  },
})

// Need to be used outside the setup
export function useUserStoreWithOut() {
  return useUserStore(store)
}
